@media (max-width: 991px) {
    #content {
        width: 100% !important;
        position: relative !important;
        overflow: hidden !important;
        padding: 50px 0 100px 0 !important;
        margin-top: 50px !important;
    }

    .nav-pills .nav-link {
        font-size: 13px !important;
    }

    .nav-pills .nav-link img{
        width: 18px;
        margin-top: 1px;
    }

    .item-button{
        width: 230px !important; 
    }
}
.item-button{
    width: 400px; 
    margin: 10px;
}
.nav-pills .nav-link img{
    margin-top: 1px;
}
#content a {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-decoration: none;
    color: #FFF;
}

#content h1 {
    margin: 0 0 10px 0;
    font-size: 40px;
    font-weight: 500;
    line-height: 56px;
    font-family: 'Skranji', cursive;
}

#content h3 {
    font-family: 'Skranji', cursive;
}