@import url(https://fonts.googleapis.com/css2?family=Skranji&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
    color: #FFF !important;
}
.bg-ungu {
    background-color: #24182F !important;
}

.bg-1E1E1E {
    background-color: #120D18;
}

.color-E42575 {
    color: #E42575;
}

.bg-card-header {
    background: rgba(255, 255, 255, 0.2) !important;
}

.container {
    max-width: 1100px !important;
}

.navbar {
    height: 73px;
}

.nav-pills button {
    border: 1px #F99A00 !important;
}

.nav-link .icon {
    margin-top: 5px;
    margin: 4px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #F99A00;
    background-color: #24182F !important;
    border: 1px #F99A00 !important;
    border-radius: 20px;
    font-size: 14px !important;
}

.nav-pills .nav-item .nav-link {
    border-radius: 25px !important;
    font-size: 14px;
}

.nav-pills button .nav-link {
    border: 1px #F99A00 !important;
    border-radius: 25px !important;
}

.nav-pills .nav-link.active {
    border: 1px solid #F99A00 !important;
}

.nav-pills .filter {
    margin-top: 10px;
    background-color: #24182F;
    border-radius: 15px;
    right: 10px !important;
}

.nav-pills .filter img {
    margin-top: -2px;
}


.nav-link {
    margin-top: 10px;
    font-family: 'Skranji', cursive;
    font-weight: 400;
    size: 14px;
    color: #fff !important;
}

.styles-font{
    font-family: 'Skranji', cursive;
}

.nav-link.active {
    font-weight: 400;
    color: #F99A00 !important;
}

.nav-link:hover {
    font-weight: 400;
    color: #F99A00 !important;
}

.color-F99A00 {
    color: #F99A00;
}

.nav-link img {
    margin-top: -10px;
}

.pencarian {
    /* box-shadow: 0 0 40px rgba(51, 51, 51, .1); */
    top: 90px;
    left: 445px;
    align-items: center;
}

.pencarian input {
    height: 40px;
    width: 383px;
    text-indent: 50px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    color: #fff;
    border: none;
}

.placeh::-webkit-input-placeholder {
    color: #FFF;
}

.placeh::-moz-input-placeholder {
    color: #FFF;
}

.placeh::-ms-input-placeholder {
    color: #FFF;
}

.pencarian input:focus {
    box-shadow: none;
    border: 1px solid #FFF;
    outline: none;
}

.pencarian .img-search {
    position: absolute;
    top: 27px;
    padding-left: 15px;
}

.pencarian-bottom {
    /* box-shadow: 0 0 40px rgba(51, 51, 51, .1); */
    top: 90px;
    left: 445px;
    align-items: center;
}

.pencarian-bottom input {
    height: 40px;
    width: 383px;
    text-indent: 50px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    color: #fff;
    border: none;
}

.pencarian-bottom input:focus {
    box-shadow: none;
    border: 1px solid #FFF;
    outline: none;
}

.pencarian-bottom .img-search-bot {
    position: absolute;
    margin-top: 11px;
    padding-left: 15px;
}

#content {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 100px 0 100px 0;
    margin-top: 55px;
}

#footer {
    background-color: #24182F;
    padding: 0 0 30px 0;
    color: #ffff;
    font-size: 14px;
}

#footer .footer-newsletter {
    padding: 50px 0;
    background-color: #24182F;
    text-align: center;
    font-size: 15px;
}

#footer .footer-top .footer-contact {
    margin-bottom: 30px;
}

.social-links a {
    margin: 10px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #9fb2d8;
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: #FFF;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
    text-decoration: none;
}

#footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
}

.radius-25 {
    border-radius: 25px;
}

.nav-explore {
    display: block;
    padding: 0.5rem 1rem;
    color: #24182F;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}
@media only screen and (max-width: 600px) {
    
}

@media only screen and (min-width: 600px) {
     
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
    #content {
        padding: 50px 20px 40px 20px !important;
        margin-top: 100px !important;
    }
    .pencarian-bottom input {
        width: 250px !important;     
    } 
}

@media only screen and (min-width: 1200px) {
    
}

@media (max-width: 991px) {
    #content {
        width: 100% !important;
        position: relative !important;
        overflow: hidden !important;
        padding: 50px 0 50px 0 !important;
        margin-top: 60px !important;
    }

    #footer .footer-top .footer-links {
        padding: 50px 40px 10px 40px;
    }

    #footer .footer-top .footer-links h4 {
        display: flex;
        justify-content: center;
    }

    #footer .footer-top .footer-links ul li {
        padding: 10px 0;
        display: flex;
        justify-content: center;
    }

    .auction-cari {
        /* box-shadow: 0 0 40px rgba(51, 51, 51, .1); */
        top: 90px;
        left: 445px;
        align-items: center;
    }

    .auction-cari input {
        height: 40px;
        width: 330px;
        text-indent: 50px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 40px;
        border: none;
        color: #FFF;
    }

    .auction-cari input ::-webkit-input-placeholder {
        color: #fff !important;
    }

    .auction-cari input ::placeholder {
        color: #fff !important;
    }
    
    .auction-cari .serch {
        position: absolute;
        margin-top: 11px;
        padding-left: 15px;
    }

    .auction-cari input:focus {
        box-shadow: none;
        border: 1px solid #FFF;
        outline: none;
    }
}

/* .pagination {
    display: inline-block;
} */

.pagination .page-item .page-link {
    color: black;
    background-color: rgba(255, 255, 255, 0.2);
    float: left;
    padding: 4px 10px;
    text-decoration: none;
    transition: background-color .3s;
    margin: 0 10px;
    border-radius: 6px;
    border: none;
}

.pagination .page-item.active .page-link {
    background-color: #F99A00;
    border: 1px solid #F99A00;
}

.mdl-img {
    width: 100%;
    height: 250px;
}

.img-center {
    text-align: center;
    margin-top: 55px;
}

.text-hijau{
    color:#44F019 !important;
}
.text-merah{
    color: #E42525 !important;
}
.text-orange{
    color: #F99A00 !important;
}
#content .blatant-img {
    text-align: center;
    margin-top: 20px;
}

.img-carousel {
    width: 330px;
    height: 339px;
    border-radius: 5px;
    margin-top: 9px;
    margin-left: 1.5px !important;
    -webkit-transform: rotate(-4.00deg);
            transform: rotate(-4.00deg);
}
.title-carousel{
    font-size: 16px;
    text-align: left;
    margin-left: 115px;
    -webkit-transform: rotate(-4.00deg);
            transform: rotate(-4.00deg);
}

.section-title {
    text-align: center;
    padding-bottom: 30px;
    color: #F99A00;
}

.home-title {
    margin: 0 0 10px 0;
    font-size: 40px;
    font-weight: 500;
    line-height: 56px;
    font-family: 'Skranji', cursive;
}

.home-desc {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
}

.treding-top {
    margin-top: 150px;
}

.service-top {
    margin-top: 150px;
}

.button-ranking {
    width: 428px;
    font-weight: bold !important;
}
@media only screen and (max-width: 600px) {
    .title-carousel{
        margin-left: 72px;
    } 
    .img-carousel {   
        margin-top: -5px;
        margin-left: -10px;
    }
}

@media only screen and (min-width: 600px) {
    .title-carousel{
        margin-left: 215px;
    } 
    .img-carousel {   
        margin-top: -5px;
        margin-left: 10px;
    }
}

@media only screen and (min-width: 768px) {
    .title-carousel{
        margin-left: 80px;
    } 
}

@media only screen and (min-width: 992px) {
    .img-carousel {   
        margin-top: 6px;
        margin-left: 4px;
    }
    .title-carousel{
        margin-left: 80px;
    } 
}

@media only screen and (min-width: 1200px) {
    .title-carousel{
        margin-left: 112px;
    } 
}

@media only screen and (max-width: 991px) {
    .home-title {
        margin: 0 0 0 0 !important;
        font-size: 26px !important;
        font-weight: 350 !important;
        line-height: 38px !important;
        font-family: 'Skranji', cursive;
    }

    .home-desc {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 13px !important;
        line-height: 14px !important;
        color: #7D7D7D;
    }

    .m-button {
        color: #F99A00;
        box-sizing: border-box;
        border: 1px solid #F99A00;
        background: #120D18;
        width: 350px;
        height: 50px;
        left: 20px;
        border-radius: 10px;
    }

    .m-button button {
        display: flex;
        justify-content: center;
    }

    .title-colle {
        font-family: 'Skranji', cursive;
        color: #F99A00;
        font-size: 20px !important;
    }

    .section-title h2 {
        text-align: center;
        padding-bottom: 30px;
        color: #F99A00 !important;
        font-size: 20px;
    }

    .background-carousel{
        width: 280px;
    }

    .img-carousel {
        width: 245px;
        height: 225px;
        border-radius: 5px;
        margin-right: -2px;
        -webkit-transform: rotate(-4.00deg);
                transform: rotate(-4.00deg);
    }
    /* .title-carousel{
        margin-left: 135px;
        transform: rotate(-4.00deg);
    } */
}

.button-item {
    color: #000;
    font-style: normal;
    box-sizing: border-box;
    border: 1px solid #F99A00;
    background: #F99A00;
    width: 428px;
    height: 45px;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    border-radius: 7px;
}
.length-search {
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    top: 90px;
    align-items: center;
}

.length-search input {
    height: 40px;
    width: 700px;
    text-indent: 50px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    border: none;
}

.length-search .icon-search {
    position: absolute;
    margin-top: 10px;
    margin-left: 18px;
}

.card-img-top {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
}

.img-init {
    border-radius: 8px;
}
.img-init:active {
    border: 1px solid #FFFF !important;
}

.img-init:hover {
    border: 1px solid #FFFF !important;
    transition: 0.5s ease !important;
}

@media (max-width: 991px) {
    .img-init:active {
        border: 1px solid #FFFF !important;
    }
    .img-init:hover {
        border: 1px solid #FFFF !important;
        transition: 0.5s ease !important;
    }
    #content {
        width: 100% !important;
        position: relative !important;
        overflow: hidden !important;
        padding: 25px 0 50px 0 !important;
        margin-top: 60px !important;
    }
    .title-img{
        position: relative;
        margin-top: -50px;
        padding: 10px;
    }
    .bg-linear{
        width: 100%;
        height: 356px;
        border-radius: 8px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 22.71%, #000000 100%) !important;
    }

    .search-modal input {
        width: 260px !important;
    }
}
.bottom-title{
    font-size: 24px !important;
    font-weight: bold;
}

.search-modal {
    position: relative;
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    align-items: center;
}

.search-modal input {
    height: 40px;
    width: 450px;
    text-indent: 50px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    border: none;
}

.search-modal input ::-webkit-input-placeholder {
    color: #fff;
}

.search-modal input ::placeholder {
    color: #fff;
}

.search-modal input:focus {
    box-shadow: none;
    border: 1px solid #FFFF;
    outline: none;
}

.search-modal .img-b {
    position: absolute;
    top: 10px;
    left: 17px;
}

.img-label1{
    padding: 0px 1px 2px 1px;
    border: 1px;
    border-radius: 13px;
    width: 50px;
    font-size: 12px;
    background-color: red;
    text-align: center;
    position: absolute;
    margin-top: 8px;
    margin-left: 10px;
}

.img-label2{
    /* padding: 0px 1px 2px 1px; */
    border: 1px;
    border-radius: 13px;
    width: 23px;
    height: 16px;
    font-size: 15px;
    background-color: black;
    /* text-align: center; */
    position: absolute;
    margin-top: 8px;
    margin-left: 103px; 
    display: flex;
    justify-content: center;
}


@media (max-width: 991px) {
    #content {
        width: 100% !important;
        position: relative !important;
        overflow: hidden !important;
        padding: 50px 0 100px 0 !important;
        margin-top: 50px !important;
    }

    .nav-pills .nav-link {
        font-size: 13px !important;
    }

    .nav-pills .nav-link img{
        width: 18px;
        margin-top: 1px;
    }

    .item-button{
        width: 230px !important; 
    }
}
.item-button{
    width: 400px; 
    margin: 10px;
}
.nav-pills .nav-link img{
    margin-top: 1px;
}
#content a {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-decoration: none;
    color: #FFF;
}

#content h1 {
    margin: 0 0 10px 0;
    font-size: 40px;
    font-weight: 500;
    line-height: 56px;
    font-family: 'Skranji', cursive;
}

#content h3 {
    font-family: 'Skranji', cursive;
}
@media (max-width: 991px) {
    #content p {
        font-size: 13px;
    }

    .search-box {
        position: relative;
        box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    }
    .search-box input{
        height: 40px;
        width: 100%;
        text-indent: 10px;
        background: rgba(255, 255, 255, 0.2);
        border: none;
        color: #FFF;
        border-radius: 5px;
    }
    .search-box input:focus {
        box-shadow: none;
        border: 1px solid #FFF;
        outline: none;
    }
    .bg-card-silver{
        background: rgba(255, 255, 255, 0.2) !important;
    }
}

.pencarian-barter{
    top: 90px;
}

.pencarian-barter input{
    height: 40px;
    width: 800px;
    text-indent: 45px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    color: #fff;
    border: none;
}


.pencarian-barter input:focus {
    box-shadow: none;
    border: 1px solid #FFF;
    outline: none;
}

.pencarian-barter .img-barter {
    position: absolute;
    margin-top: 10px;
    margin-left: 15px;
}
/* .nav-pills .nav-item button {
    width: 222px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #F99A00;
    background-color: #24182F !important;
    border-color: #F99A00;
    width: 222px;
} */

.color-E42575 {
    color: #E42575;
}

.photo-profile {
    width: 70px;
}

.profile-code{
    font-size: 16px !important;
}

@media (max-width: 991px) {
    /* #content {
        width: 100% !important;
        position: relative !important;
        overflow: hidden !important;
        padding: 30px 10px 40px 10px !important;
        margin-top: 80px !important;
    } */

    .profile-desc {
        font-size: 12px !important;
        text-align: justify !important;
        padding: 10px 0 10px 0;
    }

    .profile-name {
        margin-top: 10px;
        font-size: 15px !important;
    }
    .link-sosmed {
        display: flex;
        justify-content: center;
        padding: 0 10px 0 10px;
    }

    .link-sosmed img {
        width: 30px;
    }

    .my-wallet{
        font-size: 13px !important;
    }

    /* .nav-pills .nav-item button {
        width: 160px;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        width: 160px;
    } */
}
