/* .nav-pills .nav-item button {
    width: 222px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #F99A00;
    background-color: #24182F !important;
    border-color: #F99A00;
    width: 222px;
} */

.color-E42575 {
    color: #E42575;
}

.photo-profile {
    width: 70px;
}

.profile-code{
    font-size: 16px !important;
}

@media (max-width: 991px) {
    /* #content {
        width: 100% !important;
        position: relative !important;
        overflow: hidden !important;
        padding: 30px 10px 40px 10px !important;
        margin-top: 80px !important;
    } */

    .profile-desc {
        font-size: 12px !important;
        text-align: justify !important;
        padding: 10px 0 10px 0;
    }

    .profile-name {
        margin-top: 10px;
        font-size: 15px !important;
    }
    .link-sosmed {
        display: flex;
        justify-content: center;
        padding: 0 10px 0 10px;
    }

    .link-sosmed img {
        width: 30px;
    }

    .my-wallet{
        font-size: 13px !important;
    }

    /* .nav-pills .nav-item button {
        width: 160px;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        width: 160px;
    } */
}