@media (max-width: 991px) {
    #content p {
        font-size: 13px;
    }

    .search-box {
        position: relative;
        box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    }
    .search-box input{
        height: 40px;
        width: 100%;
        text-indent: 10px;
        background: rgba(255, 255, 255, 0.2);
        border: none;
        color: #FFF;
        border-radius: 5px;
    }
    .search-box input:focus {
        box-shadow: none;
        border: 1px solid #FFF;
        outline: none;
    }
    .bg-card-silver{
        background: rgba(255, 255, 255, 0.2) !important;
    }
}

.pencarian-barter{
    top: 90px;
}

.pencarian-barter input{
    height: 40px;
    width: 800px;
    text-indent: 45px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    color: #fff;
    border: none;
}


.pencarian-barter input:focus {
    box-shadow: none;
    border: 1px solid #FFF;
    outline: none;
}

.pencarian-barter .img-barter {
    position: absolute;
    margin-top: 10px;
    margin-left: 15px;
}