.length-search {
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    top: 90px;
    align-items: center;
}

.length-search input {
    height: 40px;
    width: 700px;
    text-indent: 50px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    border: none;
}

.length-search .icon-search {
    position: absolute;
    margin-top: 10px;
    margin-left: 18px;
}

.card-img-top {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
}

.img-init {
    border-radius: 8px;
}
.img-init:active {
    border: 1px solid #FFFF !important;
}

.img-init:hover {
    border: 1px solid #FFFF !important;
    transition: 0.5s ease !important;
}

@media (max-width: 991px) {
    .img-init:active {
        border: 1px solid #FFFF !important;
    }
    .img-init:hover {
        border: 1px solid #FFFF !important;
        transition: 0.5s ease !important;
    }
    #content {
        width: 100% !important;
        position: relative !important;
        overflow: hidden !important;
        padding: 25px 0 50px 0 !important;
        margin-top: 60px !important;
    }
    .title-img{
        position: relative;
        margin-top: -50px;
        padding: 10px;
    }
    .bg-linear{
        width: 100%;
        height: 356px;
        border-radius: 8px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 22.71%, #000000 100%) !important;
    }

    .search-modal input {
        width: 260px !important;
    }
}
.bottom-title{
    font-size: 24px !important;
    font-weight: bold;
}

.search-modal {
    position: relative;
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    align-items: center;
}

.search-modal input {
    height: 40px;
    width: 450px;
    text-indent: 50px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    border: none;
}

.search-modal input ::placeholder {
    color: #fff;
}

.search-modal input:focus {
    box-shadow: none;
    border: 1px solid #FFFF;
    outline: none;
}

.search-modal .img-b {
    position: absolute;
    top: 10px;
    left: 17px;
}

.img-label1{
    padding: 0px 1px 2px 1px;
    border: 1px;
    border-radius: 13px;
    width: 50px;
    font-size: 12px;
    background-color: red;
    text-align: center;
    position: absolute;
    margin-top: 8px;
    margin-left: 10px;
}

.img-label2{
    /* padding: 0px 1px 2px 1px; */
    border: 1px;
    border-radius: 13px;
    width: 23px;
    height: 16px;
    font-size: 15px;
    background-color: black;
    /* text-align: center; */
    position: absolute;
    margin-top: 8px;
    margin-left: 103px; 
    display: flex;
    justify-content: center;
}

