@import url('https://fonts.googleapis.com/css2?family=Skranji&display=swap');
body{
    color: #FFF !important;
}
.bg-ungu {
    background-color: #24182F !important;
}

.bg-1E1E1E {
    background-color: #120D18;
}

.color-E42575 {
    color: #E42575;
}

.bg-card-header {
    background: rgba(255, 255, 255, 0.2) !important;
}

.container {
    max-width: 1100px !important;
}

.navbar {
    height: 73px;
}

.nav-pills button {
    border: 1px #F99A00 !important;
}

.nav-link .icon {
    margin-top: 5px;
    margin: 4px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #F99A00;
    background-color: #24182F !important;
    border: 1px #F99A00 !important;
    border-radius: 20px;
    font-size: 14px !important;
}

.nav-pills .nav-item .nav-link {
    border-radius: 25px !important;
    font-size: 14px;
}

.nav-pills button .nav-link {
    border: 1px #F99A00 !important;
    border-radius: 25px !important;
}

.nav-pills .nav-link.active {
    border: 1px solid #F99A00 !important;
}

.nav-pills .filter {
    margin-top: 10px;
    background-color: #24182F;
    border-radius: 15px;
    right: 10px !important;
}

.nav-pills .filter img {
    margin-top: -2px;
}


.nav-link {
    margin-top: 10px;
    font-family: 'Skranji', cursive;
    font-weight: 400;
    size: 14px;
    color: #fff !important;
}

.styles-font{
    font-family: 'Skranji', cursive;
}

.nav-link.active {
    font-weight: 400;
    color: #F99A00 !important;
}

.nav-link:hover {
    font-weight: 400;
    color: #F99A00 !important;
}

.color-F99A00 {
    color: #F99A00;
}

.nav-link img {
    margin-top: -10px;
}

.pencarian {
    /* box-shadow: 0 0 40px rgba(51, 51, 51, .1); */
    top: 90px;
    left: 445px;
    align-items: center;
}

.pencarian input {
    height: 40px;
    width: 383px;
    text-indent: 50px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    color: #fff;
    border: none;
}

.placeh::-webkit-input-placeholder {
    color: #FFF;
}

.placeh::-moz-input-placeholder {
    color: #FFF;
}

.placeh::-ms-input-placeholder {
    color: #FFF;
}

.pencarian input:focus {
    box-shadow: none;
    border: 1px solid #FFF;
    outline: none;
}

.pencarian .img-search {
    position: absolute;
    top: 27px;
    padding-left: 15px;
}

.pencarian-bottom {
    /* box-shadow: 0 0 40px rgba(51, 51, 51, .1); */
    top: 90px;
    left: 445px;
    align-items: center;
}

.pencarian-bottom input {
    height: 40px;
    width: 383px;
    text-indent: 50px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    color: #fff;
    border: none;
}

.pencarian-bottom input:focus {
    box-shadow: none;
    border: 1px solid #FFF;
    outline: none;
}

.pencarian-bottom .img-search-bot {
    position: absolute;
    margin-top: 11px;
    padding-left: 15px;
}

#content {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 100px 0 100px 0;
    margin-top: 55px;
}

#footer {
    background-color: #24182F;
    padding: 0 0 30px 0;
    color: #ffff;
    font-size: 14px;
}

#footer .footer-newsletter {
    padding: 50px 0;
    background-color: #24182F;
    text-align: center;
    font-size: 15px;
}

#footer .footer-top .footer-contact {
    margin-bottom: 30px;
}

.social-links a {
    margin: 10px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #9fb2d8;
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: #FFF;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
    text-decoration: none;
}

#footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
}

.radius-25 {
    border-radius: 25px;
}

.nav-explore {
    display: block;
    padding: 0.5rem 1rem;
    color: #24182F;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}
@media only screen and (max-width: 600px) {
    
}

@media only screen and (min-width: 600px) {
     
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
    #content {
        padding: 50px 20px 40px 20px !important;
        margin-top: 100px !important;
    }
    .pencarian-bottom input {
        width: 250px !important;     
    } 
}

@media only screen and (min-width: 1200px) {
    
}

@media (max-width: 991px) {
    #content {
        width: 100% !important;
        position: relative !important;
        overflow: hidden !important;
        padding: 50px 0 50px 0 !important;
        margin-top: 60px !important;
    }

    #footer .footer-top .footer-links {
        padding: 50px 40px 10px 40px;
    }

    #footer .footer-top .footer-links h4 {
        display: flex;
        justify-content: center;
    }

    #footer .footer-top .footer-links ul li {
        padding: 10px 0;
        display: flex;
        justify-content: center;
    }

    .auction-cari {
        /* box-shadow: 0 0 40px rgba(51, 51, 51, .1); */
        top: 90px;
        left: 445px;
        align-items: center;
    }

    .auction-cari input {
        height: 40px;
        width: 330px;
        text-indent: 50px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 40px;
        border: none;
        color: #FFF;
    }

    .auction-cari input ::placeholder {
        color: #fff !important;
    }
    
    .auction-cari .serch {
        position: absolute;
        margin-top: 11px;
        padding-left: 15px;
    }

    .auction-cari input:focus {
        box-shadow: none;
        border: 1px solid #FFF;
        outline: none;
    }
}

/* .pagination {
    display: inline-block;
} */

.pagination .page-item .page-link {
    color: black;
    background-color: rgba(255, 255, 255, 0.2);
    float: left;
    padding: 4px 10px;
    text-decoration: none;
    transition: background-color .3s;
    margin: 0 10px;
    border-radius: 6px;
    border: none;
}

.pagination .page-item.active .page-link {
    background-color: #F99A00;
    border: 1px solid #F99A00;
}

.mdl-img {
    width: 100%;
    height: 250px;
}

.img-center {
    text-align: center;
    margin-top: 55px;
}

.text-hijau{
    color:#44F019 !important;
}
.text-merah{
    color: #E42525 !important;
}
.text-orange{
    color: #F99A00 !important;
}