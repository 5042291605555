#content .blatant-img {
    text-align: center;
    margin-top: 20px;
}

.img-carousel {
    width: 330px;
    height: 339px;
    border-radius: 5px;
    margin-top: 9px;
    margin-left: 1.5px !important;
    transform: rotate(-4.00deg);
}
.title-carousel{
    font-size: 16px;
    text-align: left;
    margin-left: 115px;
    transform: rotate(-4.00deg);
}

.section-title {
    text-align: center;
    padding-bottom: 30px;
    color: #F99A00;
}

.home-title {
    margin: 0 0 10px 0;
    font-size: 40px;
    font-weight: 500;
    line-height: 56px;
    font-family: 'Skranji', cursive;
}

.home-desc {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
}

.treding-top {
    margin-top: 150px;
}

.service-top {
    margin-top: 150px;
}

.button-ranking {
    width: 428px;
    font-weight: bold !important;
}
@media only screen and (max-width: 600px) {
    .title-carousel{
        margin-left: 72px;
    } 
    .img-carousel {   
        margin-top: -5px;
        margin-left: -10px;
    }
}

@media only screen and (min-width: 600px) {
    .title-carousel{
        margin-left: 215px;
    } 
    .img-carousel {   
        margin-top: -5px;
        margin-left: 10px;
    }
}

@media only screen and (min-width: 768px) {
    .title-carousel{
        margin-left: 80px;
    } 
}

@media only screen and (min-width: 992px) {
    .img-carousel {   
        margin-top: 6px;
        margin-left: 4px;
    }
    .title-carousel{
        margin-left: 80px;
    } 
}

@media only screen and (min-width: 1200px) {
    .title-carousel{
        margin-left: 112px;
    } 
}

@media only screen and (max-width: 991px) {
    .home-title {
        margin: 0 0 0 0 !important;
        font-size: 26px !important;
        font-weight: 350 !important;
        line-height: 38px !important;
        font-family: 'Skranji', cursive;
    }

    .home-desc {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 13px !important;
        line-height: 14px !important;
        color: #7D7D7D;
    }

    .m-button {
        color: #F99A00;
        box-sizing: border-box;
        border: 1px solid #F99A00;
        background: #120D18;
        width: 350px;
        height: 50px;
        left: 20px;
        border-radius: 10px;
    }

    .m-button button {
        display: flex;
        justify-content: center;
    }

    .title-colle {
        font-family: 'Skranji', cursive;
        color: #F99A00;
        font-size: 20px !important;
    }

    .section-title h2 {
        text-align: center;
        padding-bottom: 30px;
        color: #F99A00 !important;
        font-size: 20px;
    }

    .background-carousel{
        width: 280px;
    }

    .img-carousel {
        width: 245px;
        height: 225px;
        border-radius: 5px;
        margin-right: -2px;
        transform: rotate(-4.00deg);
    }
    /* .title-carousel{
        margin-left: 135px;
        transform: rotate(-4.00deg);
    } */
}

.button-item {
    color: #000;
    font-style: normal;
    box-sizing: border-box;
    border: 1px solid #F99A00;
    background: #F99A00;
    width: 428px;
    height: 45px;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    border-radius: 7px;
}